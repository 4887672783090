import { notification } from "ant-design-vue";

// UTIL FOR NOTIFICATIONS MESSAGE
export default function (type, message, description, duration) {
  const notificationDuration = duration !== undefined ? duration : 4.5;
  let className = "";
  if (["success"].includes(type)) {
    className = "ant-notification-success-text";
  } else if (["warning", "warn", "info"].includes(type)) {
    className = "ant-notification-warning-text";
  } else if (["error"].includes(type)) {
    className = "ant-notification-error-text";
  }

  // Clear existing notifications
  notification.destroy();

  const options = {
    duration: notificationDuration,
    message: message === "warning" ? message.response.data.error : message,
    description: description,
    class: className,
  };

  notification[type](options);
}
